import { Website } from './../models/website.model';
import { Injectable } from '@angular/core';
import { ApiCallService } from './api-call.service';

@Injectable()
export class WebsiteService {
  website: Website = new Website();

  constructor(private apiCallService: ApiCallService) {
    this.getWebsite();
  }

  getWebsite(): Promise<Website> {
    return this.apiCallService.get('')
      .then(response => this.website = response);
  }

  getPropertyByKey(keyName: string): string {
    for (const proeprty of this.website.properties) {
      if (proeprty.key === keyName) {
        return proeprty.value;
      }
    }
  }
}
