import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, Injector } from '@angular/core';
import { SeoAwareBaseComponent } from '../common-theme/seo/seo-aware-base.component';
import { Subscription } from 'rxjs';
import { BlockHelperService } from './../common-theme/index';
import { ActivatedRoute } from '@angular/router';
var OurServicesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OurServicesComponent, _super);
    function OurServicesComponent(blockHelper, route, injector) {
        var _this = _super.call(this, injector) || this;
        _this.blockHelper = blockHelper;
        _this.route = route;
        _this.injector = injector;
        _this.subscription = new Subscription();
        _this.activeImg = 1;
        return _this;
    }
    OurServicesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription.add(this.route.data.subscribe(function (data) {
            _this.blocks = data.blocks;
            _this.setTitle(_this.blockHelper.filterBlocksByKey(_this.blocks, 'seo').properties.title);
            _this.setMetaDescription(_this.blockHelper.filterBlocksByKey(_this.blocks, 'seo').properties.meta);
        }));
    };
    OurServicesComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return OurServicesComponent;
}(SeoAwareBaseComponent));
export { OurServicesComponent };
