import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ApiLoadingService {
  body: HTMLElement;
  loading: HTMLElement;
  _loaded: number;
  set loaded(loaded: number) {
    this._loaded = loaded;

    if (this.window) {
      this.loading.style.width = loaded + 'vw';
    }
  }
  get loaded(): number {
    return this._loaded;
  }

  /**
   * Inject the Angular 2 Title Service
   * @param titleService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject('WINDOW') private window: any
  ) {
    this.body = this.document.body;

    if (this.window) {
      this.loading = this.getOrCreateElement('main-loading-element');
      this.loaded = 0;
      this.loading.addEventListener(
        'transitionend',
        (event: TransitionEvent) => {
          if (!this.loading.classList.contains('loading')) {
            this.loaded = 0;
          } else if (event.propertyName === 'width') {
            this.loaded += (90 - this.loaded) / 2;
          }
        }
      );

      this.body.appendChild(this.loading);
    }
  }

  startLoading(promise?: Promise<any>): void {
    if (this.window) {
      this.body.classList.add('api-loading');
      if (this.loading.classList.contains('loading')) {
        return;
      }

      this.loaded = 0;
      this.loading.classList.add('loading');
      this.loaded = 30;

      if (promise) {
        promise.then(() => this.stopLoading())
          .catch(() => this.stopLoading());
      }
    }
  }

  stopLoading(): void {
    if (this.window) {
      this.loaded = 100;
      this.loading.classList.remove('loading');
      this.body.classList.remove('api-loading');
    }
  }

  getOrCreateElement(className: string): HTMLElement {
    if (this.window) {
      let el: HTMLElement;

      el = this.document.querySelector('div.' + className);
      if (el === null) {
        el = this.document.createElement('div');
        el.className = className;
      }
      return el;
    }
  }
}
