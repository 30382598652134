/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cookie.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./cookie.component";
var styles_CookieComponent = [i0.styles];
var RenderType_CookieComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CookieComponent, data: {} });
export { RenderType_CookieComponent as RenderType_CookieComponent };
export function View_CookieComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { cookie: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["cookie", 1]], null, 12, "div", [["class", "cookie"], ["fxLayout", "row wrap"], ["fxLayoutAlign", "center center"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (_co.onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "a", [["color", "transparent"], ["href", "/assets/cookies.pdf"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeCookie() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "row wrap"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("cookie.message")); _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("cookie.see_details")); _ck(_v, 9, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("cookie.accept")); _ck(_v, 12, 0, currVal_4); }); }
export function View_CookieComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cookie", [], null, null, null, View_CookieComponent_0, RenderType_CookieComponent)), i1.ɵdid(1, 4243456, null, 0, i5.CookieComponent, ["WINDOW", i4.TranslateService], null, null)], null, null); }
var CookieComponentNgFactory = i1.ɵccf("app-cookie", i5.CookieComponent, View_CookieComponent_Host_0, {}, {}, []);
export { CookieComponentNgFactory as CookieComponentNgFactory };
