<section
  class="pt-5 pb-5"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <div
    class="properties"
    fxLayout="row wrap"
    fxLayoutAlign="center start"
  >
    <ng-template
      ngFor
      let-partner
      [ngForOf]="partners"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        class="property"
      >
        <a
          *ngIf="blockHelper.filterBlocksByKey(this.blocks, partner).properties.link"
          [href]="blockHelper.filterBlocksByKey(this.blocks, partner).properties.link"
          target="_blank"
        >
          <img [src]="blockHelper.filterBlocksByKey(this.blocks, partner).icon.width_320_url" [alt]="partner">
        </a>
        <img
          *ngIf="!blockHelper.filterBlocksByKey(this.blocks, partner).properties.link"
          [src]="blockHelper.filterBlocksByKey(this.blocks, partner).icon.width_320_url"
        >
      </div>
    </ng-template>
  </div>
</section>
