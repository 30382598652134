<div
  class="showcase"
  fxLayout="row"
  [ngStyle]="{'background-image': 'url(' + blockImage?.image?.url + ')'}"
>
  <div *ngIf="block" class="showcase-content">
    <h1>{{ block.properties.title }}</h1>
    <span>{{ block.properties.description }}</span>
    <a *ngIf="block.properties.link" class="btn-box--white" [routerLink]="basePath + block.properties.link">
      {{ block.properties.button }}
    </a>
  </div>
  <div class="showcase-video" *ngIf="videoUri">
    <!-- https://stackoverflow.com/questions/48856562/chrome-android-video-autoplay-inside-angular-2-component?rq=1 -->
    <video
      #video
      oncanplay="this.play()"
      onloadedmetadata="this.muted = true"
      [autoplay]="true"
      loop="true"
      [muted]="true"
      playsinline="true"
      [src]="videoUri"
      [preload]="'none'"
    >
    </video>
  </div>
</div>
