import * as tslib_1 from "tslib";
var ApiError = /** @class */ (function (_super) {
    tslib_1.__extends(ApiError, _super);
    function ApiError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.errors = [];
        return _this;
    }
    return ApiError;
}(Error));
export { ApiError };
