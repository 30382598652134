/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./project-box.component";
var styles_ProjectBoxComponent = [i0.styles];
var RenderType_ProjectBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectBoxComponent, data: {} });
export { RenderType_ProjectBoxComponent as RenderType_ProjectBoxComponent };
export function View_ProjectBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "proj-box text-center"], ["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 10, "a", [["fxLayout", "column"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 3), i1.ɵdid(5, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_3 = _ck(_v, 4, 0, _co.basePath, "project-details", _co.projectKey); _ck(_v, 3, 0, currVal_3); var currVal_4 = "column"; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v, 3).target; var currVal_2 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_5 = ((_co.block.image_square == null) ? null : _co.block.image_square.width_560_url); _ck(_v, 6, 0, currVal_5); var currVal_6 = _co.block.properties.title; _ck(_v, 8, 0, currVal_6); var currVal_7 = _co.block.properties.subtitle; _ck(_v, 10, 0, currVal_7); var currVal_8 = _co.block.properties.location; _ck(_v, 12, 0, currVal_8); }); }
export function View_ProjectBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-box", [], null, null, null, View_ProjectBoxComponent_0, RenderType_ProjectBoxComponent)), i1.ɵdid(1, 180224, null, 0, i6.ProjectBoxComponent, [i1.Injector], null, null)], null, null); }
var ProjectBoxComponentNgFactory = i1.ɵccf("app-project-box", i6.ProjectBoxComponent, View_ProjectBoxComponent_Host_0, { block: "block", projectKey: "projectKey" }, {}, []);
export { ProjectBoxComponentNgFactory as ProjectBoxComponentNgFactory };
