import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { SeoAwareBaseComponent } from '../common-theme/seo/seo-aware-base.component';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Block } from './../common-theme/models/index';
import { BlockHelperService } from './../common-theme/index';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class InquiryComponent extends SeoAwareBaseComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  public blocks: Block[];

  constructor(
    public blockHelper: BlockHelperService,
    public route: ActivatedRoute,
    public injector: Injector,
    public translate: TranslateService
  ) {
    super (injector);
  }

  ngOnInit(): void {
    this.subscription.add(this.route.data.subscribe(data => {
      this.blocks = data.blocks;
      this.setTitle(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.title)
      this.setMetaDescription(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.meta)
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
