/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./re-captcha.component";
import * as i2 from "../seo/locale.service";
import * as i3 from "@angular/common";
var styles_ReCaptchaComponent = [];
var RenderType_ReCaptchaComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ReCaptchaComponent, data: {} });
export { RenderType_ReCaptchaComponent as RenderType_ReCaptchaComponent };
export function View_ReCaptchaComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "g-recaptcha"], ["data-callback", "reCaptchaVerifyCallback"], ["data-size", "invisible"]], [[1, "data-sitekey", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.siteKey; _ck(_v, 0, 0, currVal_0); }); }
export function View_ReCaptchaComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-re-captcha", [], null, null, null, View_ReCaptchaComponent_0, RenderType_ReCaptchaComponent)), i0.ɵdid(1, 114688, null, 0, i1.ReCaptchaComponent, [i0.NgZone, i2.LocaleService, i3.DOCUMENT, "WINDOW"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReCaptchaComponentNgFactory = i0.ɵccf("app-re-captcha", i1.ReCaptchaComponent, View_ReCaptchaComponent_Host_0, { siteKey: "siteKey" }, { captchaResponse: "captchaResponse" }, []);
export { ReCaptchaComponentNgFactory as ReCaptchaComponentNgFactory };
