import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

declare var fbq: any;

@Injectable()
export class FbqService {
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject('WINDOW') private window: any
  ) {
    this.init();
  }

  init(): boolean {
    if (this.window && isPlatformBrowser(this.platformId)) {
      if (environment.facebook_pixel_id) {
        if (typeof fbq === 'undefined') {
          throw new Error('Facebook Pixel is not loaded.')
        }

        fbq('init', environment.facebook_pixel_id);

        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            fbq('track', 'PageView');
          }
        });

        return true;
      }
    }

    return false;
  }

  // fbq('track', 'eventName', {customData});
  sendEvent(fbqEvent: string, customData: {}): boolean {
    if (!environment.facebook_pixel_id)
      return false;

    if (typeof fbq === 'undefined') {
      throw new Error('Facebook Pixel is not loaded.')
    }

    if (isPlatformBrowser(this.platformId))
      fbq('track', fbqEvent, customData);
    else
      return false;

    return true;
  }
}
