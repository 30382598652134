<section
  class="hero"
>
    <app-showcase
      [blockImage]="blockHelper.filterBlocksByKey(blocks, 'hero')"
      fxHide
      fxShow.gt-sm
      videoUri="https://s3.eu-central-1.amazonaws.com/video.vauntsite.ro/real-sol.ro/story.mp4"
    ></app-showcase>

    <div
        class="hero-card px-5 pb-2"
        fxLayout="column wrap"
        fxLayoutGap="2%"
    >
        <div
            class="card-header pb-2"
            fxLayoutAlign="space-around center"
            fxLayoutGap="15px"
        >
            <i class="fa fa-umbrella fa-3d"></i>
            <div>
                <h1>{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.title }}</h1>
            </div>
        </div>
        <div
            class="card-body"
            fxLayout="column"
            fxLayoutAlign="center"
            fxLayoutGap="5%"
        >
            <p style="font-weight: bold; font-style: italic;">{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.subtitle }}</p>
            <p>{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.firstp }}</p>
            <p>{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.secondp }}</p>
        </div>
        <div
            class="card-footer pt-2"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutAlign.sm="center center"
            fxLayoutGap="5%"
        >
            <span>
                Est. 2014
            </span>
            <img
                class="signature"
                [src]="blockHelper.filterBlocksByKey(blocks, 'signature').icon.url"
                alt="Signature"
            >
        </div>
    </div>
</section>

<section class="pos-relative hr-top-right pt-5 px-2">
    <div
        class="mx-auto"
        fxLayout="column wrap"
        fxLayoutAlign="center"
        fxFlex="70%"
    >
        <div class="title text-center pb-5">
            <h2> <span translate>story.believe</span></h2>
            <p class="text-center">{{ blockHelper.filterBlocksByKey(blocks, 'second-section').properties.text }}</p>
        </div>
        <div
            fxLayout="row wrap"
            fxLayout.xs="column"
            fxLayoutAlign="center space-around"
            fxLayoutGap="2%"
        >
            <div
                fxFlex="32%"
                class="icon text-center"
            >
                <div class="routing routing-excelenta">
                    <div class="iconwrap">
                        <img class="icon icon-pencil" src="/assets/svg/excelenta.svg" alt="Excellence" />
                    </div>
                    <div class="text">
                        <h4 translate>Excelență</h4>
                    </div>
                </div>

            </div>
            <div
                fxFlex="32%"
                class="icon text-center"
            >
                <div class="routing routing-creativitate">
                    <div class="iconwrap">
                        <img class="icon icon-pencil" src="/assets/svg/creativitate.svg" alt="Creativity" />
                    </div>
                    <div class="text">
                        <h4 translate>Creativitate</h4>
                    </div>
                </div>
            </div>
            <div
                fxFlex="32%"
                class="icon text-center"
            >
                <div class="routing routing-functionalitate">
                    <div class="iconwrap">
                        <img class="icon icon-pencil" src="/assets/svg/functionalitate.svg" alt="Functionality" />
                    </div>
                    <div class="text">
                        <h4 translate>Functionalitate</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="cta px-2 pb-5">
    <div
        fxLayout="column"
        fxLayoutAlign="center"
        fxFlex="70%"
        fxFlex.xs
    >
        <div clas="cta-text">
            <p class="py-5" class="quote" style="font-weight: bold;">{{ blockHelper.filterBlocksByKey(blocks, 'cta').properties.text }}</p>
        </div>
        <div
            class="cta-bttn py-2"
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="space-around center"
            fxLayoutGap="0.5%"
            fxLayoutGap.xs="5%"
        >
            <a
                [routerLink]="basePath + '/our-story'"
                class="btn-box--green"
            >
                {{ 'story.story' | translate }}
            </a>
            <hr/>
            <a
                [routerLink]="basePath + '/our-services'"
                class="btn-outline--white"
            >
                {{ 'story.services' | translate }}
            </a>
        </div>
    </div>
</section>

<section class="pos-relative hr-top-left pt-5">
    <div
        class="mgm"
        fxLayout="column"
        fxLayoutGap="5%"
        fxFlex.lg="80%"
        fxFlex="100%"
    >
        <div
            class="title title-tm pos-relative"
            fxLayout="column"
            fxLayoutAlign="center center"
        >
            <div
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="2%"
            >
                <h2>
                    <span>{{ blockHelper.filterBlocksByKey(blocks, 'top-management').properties.title }}</span>
                </h2>
            </div>

            <p class="pb-2">
                    {{ blockHelper.filterBlocksByKey(blocks, 'top-management').properties.subtitle }}
            </p>
        </div>
        <div
            class="top-management"
            fxLayout="row wrap"
            fxLayout.xs="column"
            fxLayoutAlign="space-around"
            fxLayoutAlign.sm="center"
            fxLayoutGap="3%"
        >
            <div
                class="card-manager text-center"
                fxFlex.xs="100%"
                fxFlex="45%"
            >
              <a targe="_blank" href="https://ro.linkedin.com/in/shimon-galon-75311870/" target="_blank">
                  <img class="main-img" src="/assets/img/team/shimon.jpg" alt="Shimon Galon" />
                  <div
                    class="manager-title"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                  >
                    <div>
                      <h3>Shimon Galon</h3>
                      <h4>
                        {{ blockHelper.filterBlocksByKey(blocks, 'top-management').properties.man1_job }}
                        <img class="li-icon" src="/assets/svg/linkedin.svg" alt="LinkedIn">
                      </h4>
                    </div>
                  </div>
                  <p>{{ blockHelper.filterBlocksByKey(blocks, 'top-management').properties.man1_description }}</p>
              </a>
            </div>
            <div
                class="card-manager text-center"
                fxFlex.xs="100%"
                fxFlex="45%"
            >
              <a targe="_blank" href="https://www.linkedin.com/in/elroy-knebel-52042a135/?originalSubdomain=il" target="_blank">
                  <img class="main-img" src="/assets/img/team/knebel.jpg" alt="Stefan Cremene" />
                  <div
                    class="manager-title"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                  >
                    <div>
                      <h3>
                        Elroy Knebel
                      </h3>
                      <h4>
                        {{ blockHelper.filterBlocksByKey(blocks, 'top-management').properties.man4_job }}
                        <img class="li-icon" src="/assets/svg/linkedin.svg" alt="LinkedIn">
                      </h4>
                    </div>
                  </div>
                  <p>{{ blockHelper.filterBlocksByKey(blocks, 'top-management').properties.man4_description }}</p>
              </a>
            </div>
        </div>
        <div
            class="top-management"
            fxLayout="row wrap"
            fxLayout.xs="column"
            fxLayoutAlign="space-around"
            fxLayoutAlign.sm="center"
            fxLayoutGap="3%"
        >
            <div
                class="card-manager text-center"
                fxFlex.xs="100%"
                fxFlex="45%"
            >
              <a targe="_blank" href="https://www.linkedin.com/in/stefan-cremene-72b99044/" target="_blank">
                  <img class="main-img" src="/assets/img/team/cremene.jpg" alt="Stefan Cremene" />
                  <div
                    class="manager-title"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                  >
                    <div>
                      <h3>
                        Stefan Cremene
                      </h3>
                      <h4>
                        {{ blockHelper.filterBlocksByKey(blocks, 'top-management').properties.man2_job }}
                        <img class="li-icon" src="/assets/svg/linkedin.svg" alt="LinkedIn">
                      </h4>
                    </div>
                  </div>
                  <p>{{ blockHelper.filterBlocksByKey(blocks, 'top-management').properties.man2_description }}</p>
              </a>
            </div>
            <div
                class="card-manager text-center"
                fxFlex.xs="100%"
                fxFlex="45%"
            >
                <img class="main-img" src="/assets/img/team/dana.jpg" alt="Dana Măcelaru" />
                <div
                  class="manager-title"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                >
                  <div>
                    <h3>
                      Dana Măcelaru
                    </h3>
                    <h4>
                      {{ blockHelper.filterBlocksByKey(blocks, 'top-management').properties.man3_job }}
                    </h4>
                  </div>
                </div>
                <p>{{ blockHelper.filterBlocksByKey(blocks, 'top-management').properties.man3_description }}</p>
            </div>
        </div>

        <div
            class="top-management"
            fxLayout="row wrap"
            fxLayout.xs="column"
            fxLayoutAlign="space-between"
            fxLayoutAlign.sm="center"
            fxLayoutGap="3%"
        >
          <div
            class="card-manager text-center"
            fxFlex.xs="100%"
            fxFlex="22%"
          >
            <a targe="_blank" href="https://ro.linkedin.com/in/ancastandumitrescu" target="_blank">
              <img class="main-img" src="/assets/img/team/Anca Dumitrescu.jpg" alt="Anca Dumitrescu" />
              <div
                class="manager-title"
                fxLayout="row"
                fxLayoutAlign="center start"
              >
                <div>
                  <h3>Anca Dumitrescu</h3>
                  <h4>
                    {{ 'Marketing Manager' | translate }}
                    <img class="li-icon" src="/assets/svg/linkedin.svg" alt="LinkedIn">
                  </h4>
                </div>
              </div>
            </a>
          </div>
          <div
            class="card-manager text-center"
            fxFlex.xs="100%"
            fxFlex="22%"
          >
            <a targe="_blank" href="https://www.linkedin.com/in/razvan-iliescu-53b279111" target="_blank">
              <img class="main-img" src="/assets/img/team/Razvan Iliescu.jpg" alt="Razvan Iliescu" />
              <div
                class="manager-title"
                fxLayout="row"
                fxLayoutAlign="center start"
              >
                <div>
                  <h3>Răzvan Iliescu</h3>
                  <h4>
                    {{ 'Manager tehnic' | translate }}
                    <img class="li-icon" src="/assets/svg/linkedin.svg" alt="LinkedIn">
                  </h4>
                </div>
              </div>
            </a>
          </div>
          <div
            class="card-manager text-center"
            fxFlex.xs="100%"
            fxFlex="22%"
          >
            <a targe="_blank" href="https://ro.linkedin.com/in/mihaela-alina-pavel-827b425a" target="_blank">
              <img class="main-img" src="/assets/img/team/Mihaela Pavel.jpg" alt="Mihaela Pavel" />
              <div
                class="manager-title"
                fxLayout="row"
                fxLayoutAlign="center start"
              >
                <div>
                  <h3>Mihaela Pavel</h3>
                  <h4>
                    {{ 'Property & Facility Manager' | translate }}
                    <img class="li-icon" src="/assets/svg/linkedin.svg" alt="LinkedIn">
                  </h4>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div
            class="team"
            fxLayout="column"
            fxLayout.gt-sm="row"
            fxLayoutAlign="center center"
            fxLayoutAlign.sm="center center"
            fxLayoutGap="2%"
        >
            <div
                class="pos-relative"
                fxFlex.sm="100%"
                fxFlex="80%"
            >
                <img [src]="blockHelper.filterBlocksByKey(blocks, 'team').image.width_1280_url" alt="Real Sol Team"/>
                <div
                class="ln"
                fxHide
                fxShow.gt-xs
              ></div>
            </div>
        </div>
        <div>
            <div
                class="footer-tm"
            >
                <h3>{{ 'story.footer.0' | translate }}</h3>
                <h3><span>{{ 'story.footer.1' | translate }}</span></h3>
                <h3>{{ 'story.footer.2' | translate }}</h3>
                <h3><span>{{ 'story.footer.3' | translate }}</span></h3>
            </div>
            <p
                class="qoute-text"
                translate
            >
                {{ 'story.footer_quote.0' | translate }}
                <span>
                    {{ 'story.footer_quote.1' | translate }}
                </span>
            </p>
        </div>
    </div>
</section>
