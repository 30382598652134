import * as tslib_1 from "tslib";
import { LocaleAwareBaseComponent } from '../../common-theme/index';
import { Block } from '../../common-theme/models/index';
var ProjectBoxComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProjectBoxComponent, _super);
    function ProjectBoxComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ProjectBoxComponent;
}(LocaleAwareBaseComponent));
export { ProjectBoxComponent };
