<div
  #cookie
  class="cookie"
  fxLayout="row wrap"
  fxLayoutAlign="center center"
  (window:resize)="onResize()"
>
  <label>
    {{ 'cookie.message' | translate }}
  </label>
  <div>
    <a
      target="_blank"
      color="transparent"
      href="/assets/cookies.pdf"
    >
      {{ 'cookie.see_details' | translate }}
    </a>
    <button (click)="closeCookie()">
      {{ 'cookie.accept' | translate }}
    </button>
  </div>
</div>
