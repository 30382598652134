import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, Injector } from '@angular/core';
import { SeoAwareBaseComponent } from '../common-theme/seo/seo-aware-base.component';
import { Subscription } from 'rxjs';
import { BlockHelperService } from './../common-theme/index';
import { ActivatedRoute } from '@angular/router';
var OurProjectsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OurProjectsComponent, _super);
    function OurProjectsComponent(blockHelper, route, injector) {
        var _this = _super.call(this, injector) || this;
        _this.blockHelper = blockHelper;
        _this.route = route;
        _this.injector = injector;
        _this.subscription = new Subscription();
        _this.projects = [];
        return _this;
    }
    OurProjectsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription.add(this.route.params.subscribe(function (params) {
            switch (params['filter']) {
                case 'residential':
                    _this.projects = [
                        'res01',
                        'res11',
                        'res05',
                        'res02',
                        'res03',
                        'res14',
                        'res04',
                        'res06',
                        'res07',
                        'res08',
                        'res09',
                        'res10',
                        'res12',
                        'res13',
                        'res15',
                        'res16',
                        'res17',
                        'res18',
                        'res19',
                        'res20'
                    ];
                    _this.scroll();
                    break;
                case 'office':
                    _this.projects = Array.from(Array(20).keys()).map(function (el) { return "office" + String(el + 1).padStart(2, '0'); });
                    _this.scroll();
                    break;
                case 'hotels':
                    _this.projects = Array.from(Array(20).keys()).map(function (el) { return "hotel" + String(el + 1).padStart(2, '0'); });
                    _this.scroll();
                    break;
                default:
                    _this.projects = [];
            }
        }));
        this.subscription.add(this.route.data.subscribe(function (data) {
            _this.blocks = data.blocks;
            _this.setTitle(_this.blockHelper.filterBlocksByKey(_this.blocks, 'seo').properties.title);
            _this.setMetaDescription(_this.blockHelper.filterBlocksByKey(_this.blocks, 'seo').properties.meta);
        }));
    };
    OurProjectsComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    OurProjectsComponent.prototype.scroll = function () {
        window.setTimeout(function () {
            window.scrollTo(0, 400);
        }, 100);
    };
    return OurProjectsComponent;
}(SeoAwareBaseComponent));
export { OurProjectsComponent };
