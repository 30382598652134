import { Injector, OnDestroy } from '@angular/core';
import { TranslateService, LangChangeEvent, DefaultLangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from './../../../environments/environment';
import { LocaleService } from './locale.service';
import { DOCUMENT } from '@angular/common';

export abstract class LocaleAwareBaseComponent implements OnDestroy {
  static staticBasePath: string = '/';
  protected subscription: Subscription = new Subscription();

  get basePath(): string {
    return LocaleAwareBaseComponent.staticBasePath;
  }
  set basePath(basePath: string) {
    LocaleAwareBaseComponent.staticBasePath = basePath;
  }

  constructor(
    public injector?: Injector
  ) {
    if (!injector)
      return;

    const translate = this.injector.get(TranslateService);

    translate.setDefaultLang('ro');

    if (environment.router_link_has_locale === true) {
      const localeService = this.injector.get(LocaleService);
      this.setLocale(translate, localeService.getCurrentLocale());

      this.subscription.add(translate.onDefaultLangChange.subscribe((event: DefaultLangChangeEvent) => {
        this.basePath = LocaleService.pathPrefix + event.lang;
      }));
      this.subscription.add(translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.basePath = LocaleService.pathPrefix + event.lang;
      }));
    }
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  setLocale(translate: TranslateService, locale: string): void {
    translate.use(locale);
    this.basePath = LocaleService.pathPrefix + locale;

    if (this.injector) {
      const document = this.injector.get(DOCUMENT);
      document.documentElement.lang = locale;
    }
  }
}
