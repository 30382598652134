var ApiLoadingService = /** @class */ (function () {
    /**
     * Inject the Angular 2 Title Service
     * @param titleService
     */
    function ApiLoadingService(document, window) {
        var _this = this;
        this.document = document;
        this.window = window;
        this.body = this.document.body;
        if (this.window) {
            this.loading = this.getOrCreateElement('main-loading-element');
            this.loaded = 0;
            this.loading.addEventListener('transitionend', function (event) {
                if (!_this.loading.classList.contains('loading')) {
                    _this.loaded = 0;
                }
                else if (event.propertyName === 'width') {
                    _this.loaded += (90 - _this.loaded) / 2;
                }
            });
            this.body.appendChild(this.loading);
        }
    }
    Object.defineProperty(ApiLoadingService.prototype, "loaded", {
        get: function () {
            return this._loaded;
        },
        set: function (loaded) {
            this._loaded = loaded;
            if (this.window) {
                this.loading.style.width = loaded + 'vw';
            }
        },
        enumerable: true,
        configurable: true
    });
    ApiLoadingService.prototype.startLoading = function (promise) {
        var _this = this;
        if (this.window) {
            this.body.classList.add('api-loading');
            if (this.loading.classList.contains('loading')) {
                return;
            }
            this.loaded = 0;
            this.loading.classList.add('loading');
            this.loaded = 30;
            if (promise) {
                promise.then(function () { return _this.stopLoading(); })
                    .catch(function () { return _this.stopLoading(); });
            }
        }
    };
    ApiLoadingService.prototype.stopLoading = function () {
        if (this.window) {
            this.loaded = 100;
            this.loading.classList.remove('loading');
            this.body.classList.remove('api-loading');
        }
    };
    ApiLoadingService.prototype.getOrCreateElement = function (className) {
        if (this.window) {
            var el = void 0;
            el = this.document.querySelector('div.' + className);
            if (el === null) {
                el = this.document.createElement('div');
                el.className = className;
            }
            return el;
        }
    };
    return ApiLoadingService;
}());
export { ApiLoadingService };
