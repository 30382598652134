import { Component, OnInit, Inject } from '@angular/core';
import { LocaleAwareBaseComponent } from './../../common-theme/seo/locale-aware-base.component';
import { LocaleService } from './../../common-theme/seo/locale.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { WebsiteService } from './../../common-theme/api/website.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends LocaleAwareBaseComponent implements OnInit {
  menuIsOpen: boolean = false;
  lastScrollTop: number = 0;
  desktopShowNavMenu: boolean;
  navItems: any[] = [
    {
      name: 'Povestea noastră',
      link: '/our-story'
    },
    {
      name: 'Serviciile noastre',
      link: '/our-services'
    },
    {
      name: 'Proiectele noastre',
      link: '/our-projects/residential'
    },
    {
      name: 'Parteneri',
      link: '/partners'
    },
    {
      name: 'Știri',
      link: '/news'
    },
    {
      name: 'Contact',
      link: '/inquiry'
    }
  ];

  constructor(
    public translate: TranslateService,
    public localeService: LocaleService,
    public router: Router,
    @Inject(DOCUMENT) private document: any,
    @Inject('WINDOW') private window: any,
    public websiteService: WebsiteService
  ) {
    super();
    this.router.events.subscribe(() => {
      this.menuIsOpen = false;
      if (this.document)
        this.document.body.classList.remove('no-scroll');
    });
  }

  ngOnInit(): void {
    if (this.window)
      this.desktopShowNavMenu = this.window.innerWidth >= 960;
  }

  toggleMenu(): void {
    if (this.window.innerWidth < 960) {
      this.menuIsOpen = !this.menuIsOpen;
      this.menuIsOpen ? this.document.body.classList.add('no-scroll') : this.document.body.classList.remove('no-scroll');
    }
  }

  onResize(): void {
    if (this.window.innerWidth >= 960) {
      this.menuIsOpen = false;
      this.desktopShowNavMenu = true;
      this.document.body.classList.remove('no-scroll');
    } else {
      this.desktopShowNavMenu = false;
    }
  }

  onScroll(): void {
    const scrollToTop = this.document.getElementById('scroll_to_top');
    if (this.window.scrollY > 500)
      scrollToTop.classList.add('show');
    else
      scrollToTop.classList.remove('show');
  }
}
