<div class="proj-box text-center"
  fxLayout="column"
>
  <a
    [routerLink]="[basePath, 'project-details', projectKey]"
    fxLayout="column"
  >
    <img [src]="block.image_square?.width_560_url">
    <h3>{{ block.properties.title }}</h3>
    <h4>{{ block.properties.subtitle }}</h4>
    <h5>{{ block.properties.location }}</h5>
  </a>
</div>
