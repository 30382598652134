export const environment = {
  production: true,
  apiUrl: 'https://cms-api.aesthetic-works.net',
  baseApiPath: 'v1/site',
  website_uuid: 'cd88444f-f53f-4101-a55a-49a03444ba83',
  sentry_public_dsn: null,
  re_captcha_site_key: '6LfqrKkUAAAAAL7p3wmUJpp4YWjnLLQYafCkzT1w',
  ads_tracking_id: null,
  analytics_tracking_id: 'UA-127889370-6',
  facebook_pixel_id: null,
  release: 'APP_RELEASE',
  router_link_has_locale: true,
  ads_phone_conversion_label: null
};
