import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { SeoAwareBaseComponent } from '../common-theme/seo/seo-aware-base.component';
import { Subscription } from 'rxjs';
import { Block } from '../common-theme/models/index';
import { BlockHelperService } from '../common-theme/index';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent extends SeoAwareBaseComponent implements OnInit, OnDestroy {
  public blocks: Block[];
  public block: Block;
  public partners: string[] = [];
  subscription: Subscription = new Subscription();

  constructor(
    public blockHelper: BlockHelperService,
    public route: ActivatedRoute,
    public injector: Injector
  ) {
    super (injector);
  }

  ngOnInit(): void {
    this.subscription.add(this.route.data.subscribe(data => {
      this.blocks = data.blocks;
      this.setTitle(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.title)
      this.setMetaDescription(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.meta)
    }));

    this.partners = [
      'p14',
      'p8',
      'p2',
      'p3',
      'p4',
      'p5',
      'p6',
      'p7',
      'p1',
      'p10',
      'p12',
      'p15',
      'p16'
    ];
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
