import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { LocaleAwareBaseComponent } from './../../common-theme/seo/locale-aware-base.component';
import { LocaleService } from './../../common-theme/seo/locale.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { WebsiteService } from './../../common-theme/api/website.service';
var HeaderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HeaderComponent, _super);
    function HeaderComponent(translate, localeService, router, document, window, websiteService) {
        var _this = _super.call(this) || this;
        _this.translate = translate;
        _this.localeService = localeService;
        _this.router = router;
        _this.document = document;
        _this.window = window;
        _this.websiteService = websiteService;
        _this.menuIsOpen = false;
        _this.lastScrollTop = 0;
        _this.navItems = [
            {
                name: 'Povestea noastră',
                link: '/our-story'
            },
            {
                name: 'Serviciile noastre',
                link: '/our-services'
            },
            {
                name: 'Proiectele noastre',
                link: '/our-projects/residential'
            },
            {
                name: 'Parteneri',
                link: '/partners'
            },
            {
                name: 'Știri',
                link: '/news'
            },
            {
                name: 'Contact',
                link: '/inquiry'
            }
        ];
        _this.router.events.subscribe(function () {
            _this.menuIsOpen = false;
            if (_this.document)
                _this.document.body.classList.remove('no-scroll');
        });
        return _this;
    }
    HeaderComponent.prototype.ngOnInit = function () {
        if (this.window)
            this.desktopShowNavMenu = this.window.innerWidth >= 960;
    };
    HeaderComponent.prototype.toggleMenu = function () {
        if (this.window.innerWidth < 960) {
            this.menuIsOpen = !this.menuIsOpen;
            this.menuIsOpen ? this.document.body.classList.add('no-scroll') : this.document.body.classList.remove('no-scroll');
        }
    };
    HeaderComponent.prototype.onResize = function () {
        if (this.window.innerWidth >= 960) {
            this.menuIsOpen = false;
            this.desktopShowNavMenu = true;
            this.document.body.classList.remove('no-scroll');
        }
        else {
            this.desktopShowNavMenu = false;
        }
    };
    HeaderComponent.prototype.onScroll = function () {
        var scrollToTop = this.document.getElementById('scroll_to_top');
        if (this.window.scrollY > 500)
            scrollToTop.classList.add('show');
        else
            scrollToTop.classList.remove('show');
    };
    return HeaderComponent;
}(LocaleAwareBaseComponent));
export { HeaderComponent };
