// https://github.com/xmaestro/angular2-recaptcha
// https://github.com/xmaestro/angular2-recaptcha/issues/7

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
  NgZone
} from '@angular/core';
import { environment } from './../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { LocaleService } from '../seo/locale.service'

declare var grecaptcha: any;

@Component({
  selector: 'app-re-captcha',
  template: '<div class="g-recaptcha" [attr.data-sitekey]="siteKey" data-callback="reCaptchaVerifyCallback" data-size="invisible"></div>'
})
/*Captcha functionality component*/
export class ReCaptchaComponent implements OnInit {

  @Input()
  siteKey: string = environment.re_captcha_site_key;

  @Output()
  captchaResponse: EventEmitter<string>;

  constructor(
    private zone: NgZone,
    private localeService: LocaleService,
    @Inject(DOCUMENT) private document: any,
    @Inject('WINDOW') private window: any
  ) {
    if (this.window)
      this.window['reCaptchaVerifyCallback'] = (response: any) => this.zone.run(this.recaptchaCallback.bind(this, response));
    this.captchaResponse = new EventEmitter<string>();
  }

  recaptchaCallback(response: any): void {
    this.captchaResponse.emit(response);
  }

  ngOnInit(): void {
    const doc = this.document.body as HTMLDivElement;
    const script = this.document.createElement('script');
    script.innerHTML = '';
    script.src = 'https://www.google.com/recaptcha/api.js?hl=' + this.localeService.getCurrentLocale();
    script.async = true;
    script.defer = true;
    doc.appendChild(script);
  }

  reset(): void {
    grecaptcha.reset();
  }

  execute(): void {
    grecaptcha.execute();
  }
}
