import { Routes } from '@angular/router';
import { BlocksService } from './common-theme/api/index';
import { HomeComponent } from './home/home.component';
import { OurStoryComponent } from './our-story/our-story.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { OurProjectsComponent } from './our-projects/our-projects.component';
import { NewsComponent } from './news/news.component';
import { InquiryComponent } from './inquiry/inquiry.component';
import { PartnersComponent } from './partners/partners.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { Nume1Component } from './project-details/nume1/nume1.component';
var ɵ0 = {
    blocks_categories: ['homepage']
}, ɵ1 = {
    blocks_categories: ['homepage']
}, ɵ2 = {
    blocks_categories: ['our-story']
}, ɵ3 = {
    blocks_categories: ['our-services']
}, ɵ4 = {
    blocks_categories: ['news']
}, ɵ5 = {
    blocks_categories: ['inquiry']
}, ɵ6 = {
    blocks_categories: ['partners']
}, ɵ7 = {
    blocks_categories: ['our-projects']
}, ɵ8 = {
    blocks_categories: ['our-projects']
};
var routes = [
    {
        path: '',
        component: HomeComponent,
        data: ɵ0,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale',
        component: HomeComponent,
        data: ɵ1,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/our-story',
        component: OurStoryComponent,
        data: ɵ2,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/our-services',
        component: OurServicesComponent,
        data: ɵ3,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/news',
        component: NewsComponent,
        data: ɵ4,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/inquiry',
        component: InquiryComponent,
        data: ɵ5,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/partners',
        component: PartnersComponent,
        data: ɵ6,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/our-projects/:filter',
        component: OurProjectsComponent,
        data: ɵ7,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/project-details',
        component: ProjectDetailsComponent,
        data: ɵ8,
        resolve: {
            blocks: BlocksService
        },
        children: [
            {
                path: ':project_key',
                component: Nume1Component
            }
        ]
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: ''
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
