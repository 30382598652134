import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import 'rxjs';
import { LocaleService } from './../seo/locale.service';
import { FormError, ApiError } from './../models/index';
import { ApiLoadingService } from './api-loading.service';
var ApiCallService = /** @class */ (function () {
    function ApiCallService(httpClient, apiLoadingService, localeService) {
        this.httpClient = httpClient;
        this.apiLoadingService = apiLoadingService;
        this.localeService = localeService;
        this.callsInProgress = 0;
    }
    ApiCallService.prototype.getBasePath = function () {
        return environment.baseApiPath + '/websites/' + environment.website_uuid;
    };
    ApiCallService.prototype.getHttpOptions = function () {
        return {
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': this.localeService.getCurrentLocale()
            },
            observe: 'response'
        };
    };
    ApiCallService.prototype.getSeachUrlParams = function (searchParams, sort, page) {
        var first = true;
        var path = '';
        var _loop_1 = function (field) {
            if (searchParams.hasOwnProperty(field)) {
                if (searchParams[field] !== null && searchParams[field] !== undefined && searchParams[field]) {
                    if (Array.isArray(searchParams[field])) {
                        searchParams[field].forEach(function (search) {
                            path += (first ? '?' : '&') + field + '[]=' + search;
                            first = false;
                        });
                    }
                    else {
                        path += (first ? '?' : '&') + field + '=' + searchParams[field];
                        first = false;
                    }
                }
            }
        };
        for (var field in searchParams) {
            _loop_1(field);
        }
        if (page)
            path += (first ? '?' : '&') + 'page=' + page;
        if (sort) {
            path += (first ? '?' : '&') + 'sort=' + sort;
            first = false;
        }
        return path;
    };
    ApiCallService.prototype.startApiCall = function () {
        this.callsInProgress++;
        if (this.callsInProgress === 1)
            this.apiLoadingService.startLoading();
    };
    ApiCallService.prototype.finishApiCall = function () {
        this.callsInProgress--;
        if (this.callsInProgress < 0)
            this.callsInProgress = 0;
        if (this.callsInProgress === 0)
            this.apiLoadingService.stopLoading();
    };
    // Standard HTTP requests
    ApiCallService.prototype.get = function (path, basePath) {
        var _this = this;
        if (basePath === undefined)
            basePath = this.getBasePath();
        this.startApiCall();
        return this.httpClient
            .get(environment.apiUrl + '/' + basePath + path, this.getHttpOptions())
            .toPromise()
            .then(function (response) { return _this.handleResponse(response); })
            .catch(function (response) { return _this.handleError(response); });
    };
    ApiCallService.prototype.post = function (path, data, basePath) {
        var _this = this;
        if (basePath === undefined)
            basePath = this.getBasePath();
        this.startApiCall();
        return this.httpClient
            .post(environment.apiUrl + '/' + basePath + path, data, this.getHttpOptions())
            .toPromise()
            .then(function (response) { return _this.handleResponse(response); })
            .catch(function (response) { return _this.handleError(response); });
    };
    ApiCallService.prototype.put = function (path, data, basePath) {
        var _this = this;
        if (basePath === undefined)
            basePath = this.getBasePath();
        this.startApiCall();
        return this.httpClient
            .put(environment.apiUrl + '/' + basePath + path, data, this.getHttpOptions())
            .toPromise()
            .then(function (response) { return _this.handleResponse(response); })
            .catch(function (response) { return _this.handleError(response); });
    };
    ApiCallService.prototype.delete = function (path, data, basePath) {
        var _this = this;
        if (basePath === undefined)
            basePath = this.getBasePath();
        this.startApiCall();
        return this.httpClient
            .delete(environment.apiUrl + '/' + basePath + path, this.getHttpOptions())
            .toPromise()
            .then(function (response) { return _this.handleResponse(response); })
            .catch(function (response) { return _this.handleError(response); });
    };
    ApiCallService.prototype.patch = function (path, data, basePath) {
        var _this = this;
        if (basePath === undefined)
            basePath = this.getBasePath();
        this.startApiCall();
        return this.httpClient
            .patch(environment.apiUrl + '/' + basePath + path, data, this.getHttpOptions())
            .toPromise()
            .then(function (response) { return _this.handleResponse(response); })
            .catch(function (response) { return _this.handleError(response); });
    };
    ApiCallService.prototype.head = function (path, basePath) {
        var _this = this;
        if (basePath === undefined)
            basePath = this.getBasePath();
        this.startApiCall();
        return this.httpClient
            .head(environment.apiUrl + '/' + basePath + path, this.getHttpOptions())
            .toPromise()
            .then(function (response) { return _this.handleResponse(response); })
            .catch(function (response) { return _this.handleError(response); });
    };
    ApiCallService.prototype.options = function (path, basePath) {
        var _this = this;
        if (basePath === undefined)
            basePath = this.getBasePath();
        this.startApiCall();
        return this.httpClient
            .options(environment.apiUrl + '/' + basePath + path, this.getHttpOptions())
            .toPromise()
            .then(function (response) { return _this.handleResponse(response); })
            .catch(function (response) { return _this.handleError(response); });
    };
    ApiCallService.prototype.handleResponse = function (event) {
        this.finishApiCall();
        var response = event;
        if (response.status === 204)
            return null;
        return response.body;
    };
    ApiCallService.prototype.handleError = function (errorResponse) {
        console.error(errorResponse);
        var error;
        this.finishApiCall();
        if (errorResponse.status === 400 || errorResponse.status === 422)
            error = new FormError();
        else
            error = new ApiError();
        error.code = errorResponse.status;
        error.message = errorResponse.name ? errorResponse.name + ': ' : '';
        error.message += errorResponse.message ? errorResponse.message : errorResponse;
        if (errorResponse.error)
            error.errors = errorResponse.error.errors;
        error.api_raw_response = errorResponse;
        throw error;
    };
    return ApiCallService;
}());
export { ApiCallService };
