import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

declare var gtag: any;

/**
 *    eventCategory="contact"
 *    eventAction="click"
 *    eventLabel=„phone_click"
 *
 *    eventCategory="contact"
 *    eventAction="click"
 *    eventLabel=„email_click"
 *
 *    eventCategory="contact"
 *    eventAction="click"
 *    eventLabel=„maps_click"
 *
 *    eventCategory="social_media"
 *    eventAction="click"
 *    eventLabel=„facebook_click"
 *
 *    eventCategory="social_media"
 *    eventAction="click"
 *    eventLabel=„gplus_click"
 *
 *    eventCategory="social_media"
 *    eventAction="click"
 *    eventLabel=„instagram_click"
 *
 *    eventCategory="social_media"
 *    eventAction="click"
 *    eventLabel=„pinterest_click"
 *
 *    eventCategory="social_media"
 *    eventAction="click"
 *    eventLabel=„youtube_click"
 *
 *    eventCategory="contact"
 *    eventAction="message_sent"
 *    eventLabel=„contact_form_sent“
 *
 *    eventCategory="download"
 *    eventAction="click"
 *    eventLabel=leaflet_download
 */

@Injectable()
export class GtagService {
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject('WINDOW') private window: any
  ) {
    this.init();
  }

  init(): boolean {
    if (this.window && isPlatformBrowser(this.platformId)) {
      if (environment.analytics_tracking_id || environment.ads_tracking_id) {
        if (typeof gtag === 'undefined') {
          throw new Error('Google Tag is not loaded.');
        }

        this.router.events.subscribe(event => {
          // http://blog.thecodecampus.de/angular-2-google-analytics-google-tag-manager/
          if (event instanceof NavigationEnd) {
            gtag('js', new Date());

            if (environment.analytics_tracking_id)
              gtag('config', environment.analytics_tracking_id, { page_path: event.urlAfterRedirects });
            if (environment.ads_tracking_id)
              gtag('config', environment.ads_tracking_id, { page_path: event.urlAfterRedirects });
          }
        });

        return true;
      }
    }

    return false;
  }

  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  sendEvent(eventCategory: string, eventAction: string, eventLabel: string, eventValue: string = null): boolean {
    if (!environment.analytics_tracking_id)
      return false;

    if (typeof gtag === 'undefined') {
      throw new Error('Google Tag is not loaded.');
    }

    if (isPlatformBrowser(this.platformId)) {
      gtag('event', eventAction, {
        event_category: eventCategory,
        event_label: eventLabel,
        value: eventValue
      });
    } else {
      return false;
    }

    return true;
  }
}
