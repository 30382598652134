<section class="first">
  <div class="container">
    <app-image-carousel
      *ngIf="sliders"
      [sliders]="sliders"
    >
    </app-image-carousel>
  </div>
</section>


<section
  *ngIf="block"
  class="pt-5 pb-5"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="40px"
  class="container"
>
  <div
    class="overview text-center"
  >
    <div class="title">
      <h1><span>{{ block.properties.title }}</span></h1>
    </div>
  </div>

  <div
    class="properties"
    fxLayout="column"
    fxLayoutAlign="center start"
  >
    <ng-template
      ngFor
      let-property
      [ngForOf]="['developer', 'location', 'units', 'lettable', 'status', 'website', 'notes1', 'notes2']"
    >
      <div
        *ngIf="block.properties[property]"
        fxLayout="row"
        fxLayoutAlign="center center"
        class="property"
      >
        <img [src]="'assets/project/' + property + '.svg'">
        <h3>
          <a
            *ngIf="property === 'website'"
            [href]="block.properties.website"
            target="_blank"
          >
            {{ block.properties.website }}
          </a>
          <ng-template [ngIf]="property !== 'website'">
            {{ block.properties[property] }}
          </ng-template>
        </h3>
      </div>
    </ng-template>
  </div>
</section>
