/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./partners.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/common";
import * as i5 from "./partners.component";
import * as i6 from "../common-theme/helper/block-helper.service";
import * as i7 from "@angular/router";
var styles_PartnersComponent = [i0.styles];
var RenderType_PartnersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PartnersComponent, data: {} });
export { RenderType_PartnersComponent as RenderType_PartnersComponent };
function View_PartnersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.blockHelper.filterBlocksByKey(_co.blocks, _v.parent.context.$implicit).properties.link; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.blockHelper.filterBlocksByKey(_co.blocks, _v.parent.context.$implicit).icon.width_320_url; var currVal_2 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1, currVal_2); }); }
function View_PartnersComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.blockHelper.filterBlocksByKey(_co.blocks, _v.parent.context.$implicit).icon.width_320_url; _ck(_v, 0, 0, currVal_0); }); }
function View_PartnersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "property"], ["fxLayout", "row"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PartnersComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PartnersComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.blockHelper.filterBlocksByKey(_co.blocks, _v.context.$implicit).properties.link; _ck(_v, 4, 0, currVal_2); var currVal_3 = !_co.blockHelper.filterBlocksByKey(_co.blocks, _v.context.$implicit).properties.link; _ck(_v, 6, 0, currVal_3); }, null); }
export function View_PartnersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "section", [["class", "pt-5 pb-5"], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "properties"], ["fxLayout", "row wrap"], ["fxLayoutAlign", "center start"]], null, null, null, null, null)), i1.ɵdid(4, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(5, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PartnersComponent_1)), i1.ɵdid(7, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "row wrap"; _ck(_v, 4, 0, currVal_2); var currVal_3 = "center start"; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.partners; _ck(_v, 7, 0, currVal_4); }, null); }
export function View_PartnersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-partners", [], null, null, null, View_PartnersComponent_0, RenderType_PartnersComponent)), i1.ɵdid(1, 245760, null, 0, i5.PartnersComponent, [i6.BlockHelperService, i7.ActivatedRoute, i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PartnersComponentNgFactory = i1.ɵccf("app-partners", i5.PartnersComponent, View_PartnersComponent_Host_0, {}, {}, []);
export { PartnersComponentNgFactory as PartnersComponentNgFactory };
