import { Injectable } from '@angular/core';
import { ApiLoadingService } from './api-loading.service';
import { ApiCallService } from './api-call.service';
import { Block } from './../models/index';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class BlockService implements Resolve<Block> {
  constructor(
    private apiLoadingService: ApiLoadingService,
    private apiCallService: ApiCallService
  ) { }

  getBlock(key: string, category?: string): Promise<Block> {
    const path = category ? `/${category}/blocks/${key}` : `/blocks/${key}`;
    const basePath = category ? '/block_categories' : '';

    return this.apiCallService.get(basePath + path);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const blocks = route.data['blocks'];

    let promise;

    if (blocks.length > 1) {
      const all = [];

      blocks.forEach(block => {
        all.push(this.getBlock(block.key, block.category.length ? block.category : undefined));
      });

      promise = Promise.all(all);

    } else {
      promise = this.getBlock(blocks[0].key, blocks[0].category.length ? blocks[0].category : undefined);
    }

    this.apiLoadingService.startLoading(promise);
    return promise;
  }
}
