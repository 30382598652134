<header
  (window:scroll)="onScroll()"
  (window:resize)="onResize()"
  fxLayout="row"
  fxLayoutAlign="space-between start"
>
  <div
    class="main-nav"
    fxLayout.lt-md="row"
    fxLayout="column"
    fxLayoutAlign="space-between center"
    fxFlex.lt-md
  >
    <div>
      <button
        type="button"
        class="main-menu-burger"
        [class.open]="menuIsOpen"
        (click)="toggleMenu()"
        fxHide.gt-sm
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
    <a
      class=logo-wrapper
      routerLink="/"
    >
      <img
        src="./assets/png/logo.png"
        alt="REAL SOL"
      >
      <h1>Real SOL</h1>
    </a>
    <div
      class="main-menu-wrapper"
    >
      <div
        class="contact-info-desktop"
        fxHide
      >
        <a
          class="separator"
          [attr.href]="'tel:' + websiteService.website.phone"
          *ngIf="websiteService.website.phone"
        >
          <img
            src="./assets/png/phone.png"
            alt=""
          >
          <span>{{ 'Sună' | translate }} {{ websiteService.website.phone | beautify }}</span>
        </a>
        <a
          class="separator"
          [routerLink]="basePath + '/inquiry'"
        >
          <img
            src="./assets/png/mail.png"
            alt=""
          >
          <span>{{ 'Contactează-ne' | translate }}</span>
        </a>
      </div>
    </div>
  </div>
  <nav
    class="site-nav"
    [ngClass]="{'open': (menuIsOpen || desktopShowNavMenu)}"
  >
    <div>
      <ul>
        <li *ngFor="let item of navItems">
          <a
            [routerLink]="basePath + item.link"
            routerLinkActive="active"
            (click)="toggleMenu()"
          >
            <span *ngIf="item.name == 'Concept'" class="special-char">1</span>
            <span translate [innerHTML]="item.name"></span>
          </a>
        </li>
        <li>
          <a
            href="/assets/brosura.pdf"
            target="_blank"
          >
            <span translate>Descarcă broșura</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/realsolmanagement/"
            style="padding: 0;"
            target="_blank"
          >
            <img src="/assets/svg/facebook-green.svg" alt="FB">
          </a>
          <a
            href="https://www.instagram.com/real_sol_services/"
            style="padding: 0;"
            target="_blank"
          >
            <img src="/assets/svg/instagram-green.png" alt="IG">
          </a>
          <a
            href="https://www.linkedin.com/company/real-sol-company/"
            style="padding: 0;"
            target="_blank"
          >
            <img src="/assets/svg/linkedin-green.svg" alt="L">
          </a>
        </li>
        <li>
          <a
            *ngIf="localeService.getCurrentLocale() != 'ro'"
            [href]="localeService.getLocaleUrl('ro')"
            title="română"
          >
            <img
              src="./assets/svg/i18n/ro.svg"
              alt="RO"
            >
          </a>
          <a
            *ngIf="localeService.getCurrentLocale() != 'en'"
            [href]="localeService.getLocaleUrl('en')"
            title="English"
          >
            <img
              src="./assets/svg/i18n/en.svg"
              alt="EN"
            >
          </a>
          <a
            *ngIf="localeService.getCurrentLocale() != 'pl'"
            [href]="localeService.getLocaleUrl('pl')"
            title="polski"
          >
            <img
              src="./assets/svg/i18n/pl.svg"
              alt="PL"
            >
          </a>
        </li>
      </ul>
      <div
        class="contact-info-mobile"
        fxHide.gt-sm
      >
        <a [attr.href]="'tel:' + websiteService.website.phone">
          <i class="material-icons">phone</i>
          <span>{{ 'Sună' | translate }}</span>
          <span
            fxHide
            fxShow.gt-xs
          >
            {{ websiteService.website.phone | beautify }}
          </span>
        </a>
        <a [routerLink]="basePath + '/inquiry'">
          <i class="material-icons">mail</i>
          <span>{{ 'Contactează-ne' | translate }}</span>
        </a>
      </div>
    </div>
  </nav>
</header>
