import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from './../../../environments/environment';
import { LocaleService } from './locale.service';
import { DOCUMENT } from '@angular/common';
var LocaleAwareBaseComponent = /** @class */ (function () {
    function LocaleAwareBaseComponent(injector) {
        var _this = this;
        this.injector = injector;
        this.subscription = new Subscription();
        if (!injector)
            return;
        var translate = this.injector.get(TranslateService);
        translate.setDefaultLang('ro');
        if (environment.router_link_has_locale === true) {
            var localeService = this.injector.get(LocaleService);
            this.setLocale(translate, localeService.getCurrentLocale());
            this.subscription.add(translate.onDefaultLangChange.subscribe(function (event) {
                _this.basePath = LocaleService.pathPrefix + event.lang;
            }));
            this.subscription.add(translate.onLangChange.subscribe(function (event) {
                _this.basePath = LocaleService.pathPrefix + event.lang;
            }));
        }
    }
    Object.defineProperty(LocaleAwareBaseComponent.prototype, "basePath", {
        get: function () {
            return LocaleAwareBaseComponent.staticBasePath;
        },
        set: function (basePath) {
            LocaleAwareBaseComponent.staticBasePath = basePath;
        },
        enumerable: true,
        configurable: true
    });
    LocaleAwareBaseComponent.prototype.ngOnDestroy = function () {
        if (this.subscription)
            this.subscription.unsubscribe();
    };
    LocaleAwareBaseComponent.prototype.setLocale = function (translate, locale) {
        translate.use(locale);
        this.basePath = LocaleService.pathPrefix + locale;
        if (this.injector) {
            var document_1 = this.injector.get(DOCUMENT);
            document_1.documentElement.lang = locale;
        }
    };
    LocaleAwareBaseComponent.staticBasePath = '/';
    return LocaleAwareBaseComponent;
}());
export { LocaleAwareBaseComponent };
