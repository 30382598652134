export class Contact {
  'g-recaptcha-response': string;
  first_name: string;
  last_name: string;
  message: string;
  phone: string;
  email: string;
  referrer_url: string;
  marketing_accepted: boolean = false;
}
