import * as tslib_1 from "tslib";
import { Meta, Title } from '@angular/platform-browser';
import { LocaleAwareBaseComponent } from './locale-aware-base.component';
import { DOCUMENT } from '@angular/common';
var SeoAwareBaseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SeoAwareBaseComponent, _super);
    function SeoAwareBaseComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.meta = _this.injector.get(Meta);
        _this.title = _this.injector.get(Title);
        var document = _this.injector.get(DOCUMENT);
        var window = _this.injector.get('WINDOW');
        if (document && window && document.referrer) {
            if (!document.referrer.startsWith(window.location.protocol + '//' + window.location.hostname)) {
                if (window.location.href.includes('gclid='))
                    SeoAwareBaseComponent.referrer_url = 'https://googleadservices.com';
                else
                    SeoAwareBaseComponent.referrer_url = document.referrer;
                try {
                    localStorage.setItem('last_referrer_url', SeoAwareBaseComponent.referrer_url);
                    /* tslint:disable:no-empty */
                }
                catch (e) { }
                /* tslint:enable */
            }
            else if (!SeoAwareBaseComponent.referrer_url) {
                try {
                    SeoAwareBaseComponent.referrer_url = localStorage.getItem('last_referrer_url');
                    /* tslint:disable:no-empty */
                }
                catch (e) { }
                /* tslint:enable */
            }
        }
        return _this;
    }
    SeoAwareBaseComponent.prototype.setTitle = function (messageToTranslate, additionalText) {
        var _title = (additionalText ? additionalText + ' - ' : '') + messageToTranslate;
        if (!this.isNullOrEmpty(_title)) {
            this.title.setTitle(_title);
            this.meta.addTags([
                { name: 'og:title', content: _title },
                { name: 'twitter:title', content: _title }
            ]);
        }
    };
    SeoAwareBaseComponent.prototype.setMetaDescription = function (description) {
        this.meta.addTags([
            { name: 'description', content: description },
            { name: 'og:description', content: description },
            { name: 'twitter:description', content: description }
        ]);
    };
    SeoAwareBaseComponent.prototype.isNullOrEmpty = function (value) {
        return value !== false && !value;
    };
    return SeoAwareBaseComponent;
}(LocaleAwareBaseComponent));
export { SeoAwareBaseComponent };
