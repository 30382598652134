import * as tslib_1 from "tslib";
var FormError = /** @class */ (function (_super) {
    tslib_1.__extends(FormError, _super);
    function FormError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.errors = [];
        return _this;
    }
    return FormError;
}(Error));
export { FormError };
