import * as tslib_1 from "tslib";
import { Website } from './../models/website.model';
import { ApiCallService } from './api-call.service';
var WebsiteService = /** @class */ (function () {
    function WebsiteService(apiCallService) {
        this.apiCallService = apiCallService;
        this.website = new Website();
        this.getWebsite();
    }
    WebsiteService.prototype.getWebsite = function () {
        var _this = this;
        return this.apiCallService.get('')
            .then(function (response) { return _this.website = response; });
    };
    WebsiteService.prototype.getPropertyByKey = function (keyName) {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(this.website.properties), _c = _b.next(); !_c.done; _c = _b.next()) {
                var proeprty = _c.value;
                if (proeprty.key === keyName) {
                    return proeprty.value;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    return WebsiteService;
}());
export { WebsiteService };
