<footer
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayout.gt-sm="row"
  fxLayoutAlign.gt-sm="space-between center"
  fxLayoutGap="20px"
>
  <a
    href="/"
    class="footer-branding"
  >
    <img
      src="./assets/png/logo.png"
      alt="Real SOL"
    >
  </a>

  <div
    class="footer-copyright"
    fxLayout="column"
  >
    <span>&copy; {{ currentYear }} Real SOL </span>
    <span>All rights reserved</span>
  </div>

  <a
    href="https://aesthetic-works.com/"
    class="footer-branding"
    target="_blank"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <span>Website made by </span>
    <img
      src="./assets/png/aw_logo.png"
      alt="Aesthetic Works"
    >
  </a>
</footer>
