import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Inject
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})

export class CookieComponent implements AfterViewInit {
  @ViewChild('cookie', { static: false }) cookie: ElementRef;
  showFooter: boolean = false;

  constructor(
    @Inject('WINDOW') private window: any,
    public translate: TranslateService
  ) {
  }

  ngAfterViewInit(): void {
    if (this.window)
      this.onResize();
  }

  closeCookie(): void {
    this.cookie.nativeElement.style.height = '0px';

    try {
      localStorage.setItem('website_cookie_consent_closed', 'true');
    /* tslint:disable:no-empty */
    } catch (e) { }
    /* tslint:enable */
  }

  onResize(): void {
    let close = false;

    try {
      close = localStorage.getItem('website_cookie_consent_closed') === 'true';
    /* tslint:disable:no-empty */
    } catch (e) { }
    /* tslint:enable */

    if (close) {
      this.closeCookie();
    } else {
      this.cookie.nativeElement.style.height = 'auto';
      this.cookie.nativeElement.style.height = (this.cookie.nativeElement.offsetHeight + 19) + 'px';
    }
  }
}
