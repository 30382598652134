<section class="news">
  <div class="container">
    <div
      fxFlex="column"
      fxFlex="100%"
    >
      <div
        *ngFor="let news of ['news1']"
        class="card-news"
        fxLayout="column"
        fxLayout.gt-xs="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="20px"
      >
        <div
          fxFlex="40%"
        >
          <a [href]="blockHelper.filterBlocksByKey(blocks, news).image.url">
            <img
              [src]="blockHelper.filterBlocksByKey(blocks, news).image.width_560_url"
              [alt]="blockHelper.filterBlocksByKey(blocks, news).image.title"
            >
          </a>
        </div>

        <div
          fxFlex="60%"
        >
          <h2>{{ blockHelper.filterBlocksByKey(blocks, news).properties.title }}</h2>
          <h4>{{ blockHelper.filterBlocksByKey(blocks, news).properties.date }}</h4>
          <div class="text">
            <p [innerHTML]="blockHelper.filterBlocksByKey(blocks, news).properties.firstp"></p>
            <p [innerHTML]="blockHelper.filterBlocksByKey(blocks, news).properties.qoute"></p>
            <p [innerHTML]="blockHelper.filterBlocksByKey(blocks, news).properties.secondp"></p>
            <a
              *ngIf="blockHelper.filterBlocksByKey(blocks, news).properties.link"
              [href]="blockHelper.filterBlocksByKey(blocks, news).properties.link"
              target="_blank"
            >
              {{ blockHelper.filterBlocksByKey(blocks, news).properties.link_text }}
            </a>
          </div>
        </div>
      </div>

      <div>
        <p class="qoute-text">{{ blockHelper.filterBlocksByKey(blocks, 'qoute').properties.text }}</p>
      </div>
      <div
        class="py-5"
        fxLayout="row"
        fxLayout.sm="column"
        fxLayoutAlign="space-around center"
      >
        <a
          [routerLink]="basePath + '/our-projects/residential'"
          class="btn-box--green"
        >
          {{ 'news.our_projects' | translate }}
        </a>
        <a
          [routerLink]="basePath + '/inquiry'"
          class="btn-outline--white"
        >
          {{ 'news.contact' | translate }}
        </a>
      </div>
    </div>
  </div>
</section>
