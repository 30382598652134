<app-showcase
  [block]="blockHelper.filterBlocksByKey(blocks, 'hero')"
  [blockImage]="blockHelper.filterBlocksByKey(blocks, 'hero')"
  videoUri="https://s3.eu-central-1.amazonaws.com/video.vauntsite.ro/real-sol.ro/home.m4v"
></app-showcase>

<section class="projects-overview hr-top-right">
  <div
    fxLayout="row wrap"
    fxLayout.sm="column"
    fxLayoutGap.sm="0.2%"
    fxLayoutAlign.sm="center"
    fxLayoutAlign.gt-sm="flex-end start"
  >
    <div
      fxFlex.md
      fxLayout.md="column"
      fxLayoutAlign.md="start start"
      class="po-details pos-relative"
    >
      <div class="title pos-relative">
        <div
          fxLayout="row"
          fxLayoutGap="10px"
          fxLayoutAlign="center center"
        >
          <img class="gear" src="/assets/svg/gear.svg" alt="Gear">
          <h2>{{ blockHelper.filterBlocksByKey(blocks, 'projects_overview').properties.title }}</h2>
        </div>

          <p class="pb-2">
            {{ blockHelper.filterBlocksByKey(blocks, 'projects_overview').properties.subtitle }}
          </p>
          <div class="border-bottom-right"></div>
      </div>

      <div class="py-5 pos-relative" style="width: 100%;">
        <p style="font-weight: bold;">
            {{ blockHelper.filterBlocksByKey(blocks, 'projects_overview').properties.description }}
        </p>
        <div class="border-bottom-right"></div>
      </div>

      <div
        fxLayout="row"
        class="links"
        fxLayoutAlign="space-between"
      >
        <a
          [routerLink]="basePath + '/our-projects/residential'"
          class="btn-outline--white-s"
        >
          <span translate>Proiecte</span>
        </a>
        <a
          class="btn-outline--white-s"
          href="/assets/brosura.pdf"
          target="_blank"
        >
          <span translate>Broșură</span>
        </a>
      </div>
    </div>
    <div
      fxLayout="row wrap"
      fxLayout.xs="column"
      fxFlex.xs
      fxFlex.gt-md="70%"
      fxLayoutGap="10px"
    >
      <div
        fxFlex.xs
        fxFlex.sm="30%"
        fxFlex.gt-sm="30%"
        class="po-block"
        [routerLink]="[basePath, 'our-projects', blockHelper.filterBlocksByKey(blocks, 'po_image_3').properties.link]"
      >
          <img
            [src]="blockHelper.filterBlocksByKey(blocks, 'po_image_3').image.width_560_url"
            [alt]="blockHelper.filterBlocksByKey(blocks, 'po_image_3').properties.title"
          >
          <div class="text-center pos-relative mt-2">
              <div class="counter">
                  <span>{{ blockHelper.filterBlocksByKey(blocks, 'po_image_3').properties.title }}</span>
              </div>
          </div>
      </div>
      <div
        fxFlex.xs
        fxFlex.sm="30%"
        fxFlex.gt-sm="30%"
        class="po-block"
        [routerLink]="[basePath, 'our-projects', blockHelper.filterBlocksByKey(blocks, 'po_image_2').properties.link]"
      >
          <img
            [src]="blockHelper.filterBlocksByKey(blocks, 'po_image_2').image.width_560_url"
            [alt]="blockHelper.filterBlocksByKey(blocks, 'po_image_2').properties.title"
          >
          <div class="text-center pos-relative mt-2">
              <div class="counter">
                  <span>{{ blockHelper.filterBlocksByKey(blocks, 'po_image_2').properties.title }}</span>
              </div>
          </div>
      </div>
      <div
        fxFlex.xs
        fxFlex.sm="30%"
        fxFlex.gt-sm="30%"
        class="po-block"
        [routerLink]="[basePath, 'our-projects', blockHelper.filterBlocksByKey(blocks, 'po_image_1').properties.link]"
      >
          <img
            [src]="blockHelper.filterBlocksByKey(blocks, 'po_image_1').image.width_560_url"
            [alt]="blockHelper.filterBlocksByKey(blocks, 'po_image_1').properties.title"
          >
          <div class="text-center pos-relative mt-2">
              <div class="counter">
                  <span>{{ blockHelper.filterBlocksByKey(blocks, 'po_image_1').properties.title }}</span>
              </div>
          </div>
      </div>
    </div>
  </div>
</section>

<section class="qoute px-2 t">
  <div
    class="py-2"
    fxLayout="row"
    fxLayoutAlign="center"
  >
    <p class="qoute-text">{{ blockHelper.filterBlocksByKey(blocks, 'qoute').properties.text }}</p>
  </div>
</section>

<section class="cta hr-top-left px-2">
  <div
    fxLayout="column"
    fxLayoutAlign="center"
    fxFlex="70%"
    fxFlex.xs
  >
    <div class="cta-text">
      <p class="py-5">{{ blockHelper.filterBlocksByKey(blocks, 'cta').properties.text }}</p>
    </div>
    <div
      class="cta-bttn py-2"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="space-around center"
      fxLayoutGap="0.5%"
      fxLayoutGap.xs="5%"
    >
    <a
      [routerLink]="basePath + '/our-projects/residential'"
      class="btn-box--green"
    >
      {{ 'Povestea noastră' | translate }}
    </a>
    <hr/>
    <a
      [routerLink]="basePath + '/our-services'"
      class="btn-outline--white"
    >
      {{ 'Serviciile noastre' | translate }}
    </a>
    </div>
  </div>
</section>
