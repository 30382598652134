import { Injectable } from '@angular/core';
import { ApiLoadingService } from './api-loading.service';
import { ApiCallService } from './api-call.service';
import { Block } from './../models/index';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class BlocksService implements Resolve<Block[]> {
  constructor(
    private apiLoadingService: ApiLoadingService,
    private apiCallService: ApiCallService
  ) { }

  getBlocks(category: string): Promise<Block[]> {
    return this.apiCallService.get(`/block_categories/${category}/blocks`);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    let promise: any;
    const categories = route.data['blocks_categories'];

    if (categories.length > 1) {
      const all = [];

      categories.forEach(category => {
        all.push(this.getBlocks(category));
      });

      promise = Promise.all(all);

    } else {
      promise = this.getBlocks(categories[0]);
    }

    this.apiLoadingService.startLoading(promise);
    return promise;
  }
}
