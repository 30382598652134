import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class EmbedVideoService {
  constructor(
    private sanitizer: DomSanitizer
  ) { }

  public embed(urlString: any, options?: any): any {
    const id = this.detectYoutube(urlString);
    if (id !== null && id !== undefined && id !== '') {
      return this.embed_youtube(id, options);
    }
  }

  public embed_youtube(id: string, options?: any): string {
    options = this.parseOptions(options);

    return this.sanitize_iframe('<iframe src="https://www.youtube.com/embed/'
      + id + options.query + '?rel=0&amp;showinfo=0' + '"' + options.attr
      + ' frameborder="0" modestbranding="1" allowfullscreen></iframe>');
  }

  private detectYoutube(url: any): string {
    if (url) {
      if (url.indexOf('youtube.com') !== -1) {
        return this.getParameterByName(url, 'v');
      }

      if (url.indexOf('youtu.be') !== -1) {
        return url.split('be/')[1];
      }
    }

    return '';
  }

  private getParameterByName(url: string, name: string): string {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(url);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  private parseOptions(options: any): any {
    let queryString = ''
    let attributes = '';

    if (options && options.hasOwnProperty('query')) {
      queryString = '?' + this.serializeQuery(options.query);
    }

    if (options && options.hasOwnProperty('attr')) {
      const temp = [];

      Object.keys(options.attr).forEach((key) => {
        temp.push(key + '="' + (options.attr[key]) + '"');
      });

      attributes = ' ' + temp.join(' ');
    }

    return {
      query: queryString,
      attr: attributes
    };
  }

  private serializeQuery(query: any): any {
    const queryString: any = [];

    for (const p in query) {
      if (query.hasOwnProperty(p)) {
        queryString.push(encodeURIComponent(p) + '=' + encodeURIComponent(query[p]));
      }
    }

    return queryString.join('&');
  }

  private sanitize_iframe(iframe: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(iframe);
  }
}
