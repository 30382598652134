import { Injector } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { LocaleAwareBaseComponent } from './locale-aware-base.component';
import { DOCUMENT } from '@angular/common';

export abstract class SeoAwareBaseComponent extends LocaleAwareBaseComponent {
  static referrer_url: string;

  protected meta: Meta;
  protected title: Title;

  constructor(
    public injector: Injector
  ) {
    super(injector);
    this.meta = this.injector.get(Meta);
    this.title = this.injector.get(Title);

    const document = this.injector.get(DOCUMENT);
    const window = this.injector.get('WINDOW');
    if (document && window && document.referrer) {
      if (!document.referrer.startsWith(window.location.protocol + '//' + window.location.hostname)) {

        if (window.location.href.includes('gclid='))
          SeoAwareBaseComponent.referrer_url = 'https://googleadservices.com';
        else
          SeoAwareBaseComponent.referrer_url = document.referrer;

        try {
          localStorage.setItem('last_referrer_url', SeoAwareBaseComponent.referrer_url);
        /* tslint:disable:no-empty */
        } catch (e) { }
        /* tslint:enable */
      } else if (!SeoAwareBaseComponent.referrer_url) {
        try {
          SeoAwareBaseComponent.referrer_url = localStorage.getItem('last_referrer_url');
        /* tslint:disable:no-empty */
        } catch (e) { }
        /* tslint:enable */
      }
    }
  }

  public setTitle(messageToTranslate: string, additionalText?: string): void {
    const _title = (additionalText ? additionalText + ' - ' : '') + messageToTranslate;

    if (!this.isNullOrEmpty(_title)) {
      this.title.setTitle(_title);
      this.meta.addTags([
        { name: 'og:title', content: _title },
        { name: 'twitter:title', content: _title }
      ]);
    }
  }

  public setMetaDescription(description: string): void {
    this.meta.addTags([
      { name: 'description', content: description },
      { name: 'og:description', content: description },
      { name: 'twitter:description', content: description }
    ]);
  }

  private isNullOrEmpty(value: any): boolean {
    return value !== false && !value;
  }
}
