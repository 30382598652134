import { Component, Input } from '@angular/core';
import { LocaleAwareBaseComponent } from '../../common-theme/index';
import { Block } from '../../common-theme/models/index';

@Component({
  selector: 'app-project-box',
  templateUrl: './project-box.component.html',
  styleUrls: ['./project-box.component.scss']
})
export class ProjectBoxComponent extends LocaleAwareBaseComponent {
  @Input() block: Block;
  @Input() projectKey: string;
}
