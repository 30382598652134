import { Property, Block } from './../models/index';
import { Injectable } from '@angular/core';

@Injectable()
export class BlockHelperService {

  public filterUnderscore(str: string): string {
    return str.split('_').join(' ');
  }

  public getPropertiesKeys(obj: Block): any[] {
    return Object.keys(obj.properties);
  }

  public getPropertiesValues(obj: Block): any[] {
    const values = [];

    this.getPropertiesKeys(obj).forEach((key) => values.push(obj.properties[key]));

    return values;
  }

  public getProperties(obj: Block): Property[] {
    const values = [];

    this.getPropertiesKeys(obj).forEach((key) => {
      values.push(new Property(key, obj.properties[key]));
    });

    return values;
  }

  public filterBlocksByCategory(blocks: Block[][], category: string): Block[] {
    return blocks.find(block => {
      if (block[0])
        return block[0].block_category.key === category;
    });
  }

  public filterBlocksByKey(blocks: Block[], key: string): Block {
    return blocks.find(block => {
      return block.key === key;
    });
  }
}
