export class Website {
  id: number;
  uuid: string;
  title: string;
  url: string;
  address: string;
  latitude: number;
  longitude: number;
  phone: string;
  email: string;
  secondary_phone: string;
  active: boolean;
  properties: any = {};
}
