import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BlocksService } from './common-theme/api/index';
import { HomeComponent } from './home/home.component';
import { OurStoryComponent } from './our-story/our-story.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { OurProjectsComponent } from './our-projects/our-projects.component';
import { NewsComponent } from './news/news.component';
import { InquiryComponent } from './inquiry/inquiry.component';
import { PartnersComponent } from './partners/partners.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { Nume1Component } from './project-details/nume1/nume1.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      blocks_categories: ['homepage']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale',
    component: HomeComponent,
    data: {
      blocks_categories: ['homepage']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/our-story',
    component: OurStoryComponent,
    data: {
      blocks_categories: ['our-story']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/our-services',
    component: OurServicesComponent,
    data: {
      blocks_categories: ['our-services']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/news',
    component: NewsComponent,
    data: {
      blocks_categories: ['news']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/inquiry',
    component: InquiryComponent,
    data: {
      blocks_categories: ['inquiry']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/partners',
    component: PartnersComponent,
    data: {
      blocks_categories: ['partners']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/our-projects/:filter',
    component: OurProjectsComponent,
    data: {
      blocks_categories: ['our-projects']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/project-details',
    component: ProjectDetailsComponent,
    data: {
      blocks_categories: ['our-projects']
    },
    resolve: {
      blocks: BlocksService
    },
    children: [
      {
        path: ':project_key',
        component: Nume1Component
      }
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
