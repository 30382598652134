<section
  class="hero"
>
  <app-showcase
    [blockImage]="blockHelper.filterBlocksByKey(blocks, 'hero')"
    fxHide
    fxShow.gt-sm
    videoUri="https://s3.eu-central-1.amazonaws.com/video.vauntsite.ro/real-sol.ro/services.mp4"
  ></app-showcase>

  <div
    class="hero-card pb-2"
    fxLayout="column wrap"
    fxLayoutGap="2%"
  >
    <div
      class="card-header pb-2"
      fxLayoutAlign="start center"
      fxLayoutGap="15px"
    >
      <img class="gear" src="/assets/svg/gear.svg" alt="Gear">
      <div>
        <h1>
          {{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.title }}
        </h1>
      </div>
    </div>
    <div
      class="card-body"
      fxLayout="column"
      fxLayoutAlign="center"
      fxLayoutGap="5%"
    >
      <p>
        {{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.firstp }}
      </p>
      <p style="font-weight: bold;">
        {{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.quote }}
      </p>
      <p>
        {{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.secondp }}
      </p>
    </div>
    <div
      class="card-footer pt-2 px-2"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="5%"
    >
      <a
        [routerLink]="basePath + '/our-projects/residential'"
        class="btn-box--green"
        fxFlex="50%"
      >
        {{ 'services.our_projects' | translate }}
      </a>
      <a
        [routerLink]="basePath + '/inquiry'"
        class="btn-outline--white"
        fxFlex="50%"
      >
        {{ 'services.contact' | translate }}
      </a>
    </div>
  </div>
</section>
<section class="pos-relative hr-top-right pt-5">
  <div
    class="services"
    fxLayout="row wrap"
    fxLayoutAlign="center start"
    fxLayoutAlign.gt-sm="space-between start"
  >
    <div
      *ngFor="let i of [1,2,3,4,5,6]"
      class="service {{ 'service-' + i }}"
    >
      <img [src]="blockHelper.filterBlocksByKey(blocks, 'asset_' + i).icon_square.url" alt="">
      <h3>
        {{ blockHelper.filterBlocksByKey(blocks, 'asset_' + i).properties.text_button }}
        <a
          *ngIf="i == 5"
          target="_blank"
          href="https://manage-sol.ro/"
        >
          manage-sol.ro
        </a>
      </h3>
      <ul *ngIf="blockHelper.filterBlocksByKey(blocks, 'asset_' + i).properties.description">
        <li
          *ngFor="let service of blockHelper.filterBlocksByKey(blocks, 'asset_' + i).properties.description.split(',')"
        >
          {{ service }}
        </li>
      </ul>
    </div>
  </div>
</section>
