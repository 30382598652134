export class Property {
  id: string | number;
  value: any;

  constructor(
    id: string| number,
    value: any
  ) {
    this.id = id;
    this.value = value;
  }
}
