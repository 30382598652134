import { exportTranslateStaticLoader } from './i18n/translate-browser-loader.service';
/* tslint:disable:only-arrow-functions */
export function getWindow() {
    return (typeof window !== 'undefined') ? window : null;
}
export function getScreen() {
    return (typeof screen !== 'undefined') ? screen : null;
}
var ɵ0 = exportTranslateStaticLoader;
var CommonThemeModule = /** @class */ (function () {
    function CommonThemeModule() {
    }
    return CommonThemeModule;
}());
export { CommonThemeModule };
export { ɵ0 };
