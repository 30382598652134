<section
  class="hero"
>
  <app-showcase
    [blockImage]="blockHelper.filterBlocksByKey(blocks, 'contact')"
    fxHide
    fxShow.gt-sm
  ></app-showcase>

  <div
    class="hero-card pb-2"
    fxLayout="column wrap"
    fxLayoutGap="2%"
  >
    <div
      class="card-header pb-2"
      fxLayoutAlign="start center"
      fxLayoutGap="15px"
    >
      <img src="/assets/png/logo.png" alt="logo">
      <div>
        <h1>
          Real Sol
        </h1>
      </div>
    </div>
    <address class="card-body">
      <a
        target="_blank"
        href="https://maps.google.com/?daddr=44.5283205,26.0711513&ll="
        style="white-space: pre-wrap; font-weight: bold;"
      >{{ blockHelper.filterBlocksByKey(blocks, 'contact').properties.title_address }}</a>
      <a
        target="_blank"
        href="https://maps.google.com/?daddr=44.5283205,26.0711513&ll="
        style="white-space: pre-wrap;"
      >{{ blockHelper.filterBlocksByKey(blocks, 'contact').properties.address }}</a>
      <a
        target="_blank"
        [href]="'tel:' + blockHelper.filterBlocksByKey(blocks, 'contact').properties.phone"
      >
        {{ blockHelper.filterBlocksByKey(blocks, 'contact').properties.phone }}
      </a>
      <a
        target="_blank"
        [href]="'mailto:' + blockHelper.filterBlocksByKey(blocks, 'contact').properties.email"
      >
        {{ blockHelper.filterBlocksByKey(blocks, 'contact').properties.email }}
      </a>
      <div class="social">
        Social Media
        <br>
        <br>

        <a
          href="https://www.facebook.com/realsolmanagement/"
          target="_blank"
        >
          <img src="/assets/svg/facebook-green.svg" alt="FB">
        </a>
        <a
          href="https://www.instagram.com/real_sol_services/"
          target="_blank"
        >
          <img src="/assets/svg/instagram-green.png" alt="IG">
        </a>
      </div>
    </address>
  </div>
</section>

<section class="inquiry-page pt-5">
  <div class="container">
    <div class="inquiry-header">
      <h1 class="special-letter-wrapper">{{ 'Contact' | translate }}</h1>
    </div>

    <app-contact-form-base>
    </app-contact-form-base>
  </div>

  <iframe class="gmaps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d35714.22926986891!2d26.107218484572094!3d44.515206227554394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b202b79dd82fbd%3A0x724246fc11fc590c!2sStrada%20Erou%20Iancu%20Nicolae%2079%2C%20Voluntari%20077190!5e0!3m2!1sen!2sro!4v1675206043735!5m2!1sen!2sro" width="800" height="600" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
</section>
