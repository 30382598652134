<app-showcase
  [block]="blockHelper.filterBlocksByKey(blocks, 'hero')"
  [blockImage]="blockHelper.filterBlocksByKey(blocks, 'hero')"
  videoUri="https://s3.eu-central-1.amazonaws.com/video.vauntsite.ro/real-sol.ro/projects.m4v"
></app-showcase>

<div class="container">
  <div
    class="cta-bttn py-2"
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutAlign="space-around center"
    fxLayoutGap="15px"
  >
    <a
      [routerLink]="[basePath, 'our-projects', 'residential']"
      routerLinkActive="btn-box--green"
      class="btn-outline--white"
      translate
    >
      {{ 'Rezidențial' | translate }}
    </a>

    <a
      [routerLink]="[basePath, 'our-projects', 'office']"
      routerLinkActive="btn-box--green"
      class="btn-outline--white"
      translate
    >
      {{ 'Birouri' | translate }}
    </a>

    <a
      [routerLink]="[basePath, 'our-projects', 'hotels']"
      routerLinkActive="btn-box--green"
      class="btn-outline--white"
      translate
    >
      {{ 'Hoteluri' | translate }}
    </a>
  </div>
</div>

<div class="container pt-5">
  <div
    fxLayout="row wrap"
    fxLayoutAlign="center start"
    fxLayoutGap="2%"
  >
    <ng-template
      ngFor
      let-project
      [ngForOf]="projects"
    >
      <app-project-box
        *ngIf="blockHelper.filterBlocksByKey(blocks, project)"
        fxFlex.gt-xs="48%"
        fxFlex.gt-sm="31%"
        [projectKey]="project"
        [block]="blockHelper.filterBlocksByKey(blocks, project)"
      ></app-project-box>
    </ng-template>
  </div>
</div>

<div
  class="container"
>
  <section class="qoute px-2 t">
    <div
      class="py-2"
      fxLayout="row"
      fxLayoutAlign="center"
    >
      <p class="qoute-text">{{ blockHelper.filterBlocksByKey(blocks, 'qoute').properties.text }}</p>
    </div>
  </section>

  <div
    class="cta cta-bttn py-2"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="space-around center"
    fxLayoutGap="0.5%"
    fxLayoutGap.xs="5%"
  >
    <a
      [routerLink]="basePath + '/our-story'"
      class="btn-box--green"
      translate
    >
      Povestea noastră
    </a>
    <hr/>
    <a
      [routerLink]="basePath + '/our-services'"
      class="btn-outline--white"
      translate
    >
      Serviciile noastre
    </a>
  </div>
</div>
