import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, Injector } from '@angular/core';
import { SeoAwareBaseComponent } from '../common-theme/seo/seo-aware-base.component';
import { Subscription } from 'rxjs';
import { BlockHelperService } from '../common-theme/index';
import { ActivatedRoute } from '@angular/router';
var PartnersComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PartnersComponent, _super);
    function PartnersComponent(blockHelper, route, injector) {
        var _this = _super.call(this, injector) || this;
        _this.blockHelper = blockHelper;
        _this.route = route;
        _this.injector = injector;
        _this.partners = [];
        _this.subscription = new Subscription();
        return _this;
    }
    PartnersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription.add(this.route.data.subscribe(function (data) {
            _this.blocks = data.blocks;
            _this.setTitle(_this.blockHelper.filterBlocksByKey(_this.blocks, 'seo').properties.title);
            _this.setMetaDescription(_this.blockHelper.filterBlocksByKey(_this.blocks, 'seo').properties.meta);
        }));
        this.partners = [
            'p14',
            'p8',
            'p2',
            'p3',
            'p4',
            'p5',
            'p6',
            'p7',
            'p1',
            'p10',
            'p12',
            'p15',
            'p16'
        ];
    };
    PartnersComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return PartnersComponent;
}(SeoAwareBaseComponent));
export { PartnersComponent };
