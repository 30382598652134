import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { SeoAwareBaseComponent } from '../common-theme/seo/seo-aware-base.component';
import { Subscription } from 'rxjs';
import { Block } from './../common-theme/models/index';
import { BlockHelperService } from './../common-theme/index';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-our-projects',
  templateUrl: './our-projects.component.html',
  styleUrls: ['./our-projects.component.scss']
})
export class OurProjectsComponent extends SeoAwareBaseComponent implements OnInit, OnDestroy {
  public blocks: Block[];
  subscription: Subscription = new Subscription();
  projects: string[] = [];

  constructor(
    public blockHelper: BlockHelperService,
    public route: ActivatedRoute,
    public injector: Injector
  ) {
    super (injector);
  }
  ngOnInit(): void {
    this.subscription.add(this.route.params.subscribe(params => {
      switch (params['filter']) {
        case 'residential':
          this.projects = [
            'res01',
            'res11',
            'res05',
            'res02',
            'res03',
            'res14',
            'res04',
            'res06',
            'res07',
            'res08',
            'res09',
            'res10',
            'res12',
            'res13',
            'res15',
            'res16',
            'res17',
            'res18',
            'res19',
            'res20'
          ];
          this.scroll();
          break;
        case 'office':
          this.projects = Array.from(Array(20).keys()).map(el => `office${String(el + 1).padStart(2, '0')}`);
          this.scroll();
          break;
        case 'hotels':
          this.projects = Array.from(Array(20).keys()).map(el => `hotel${String(el + 1).padStart(2, '0')}`);
          this.scroll();
          break;
        default:
          this.projects = [];
      }
    }));
    this.subscription.add(this.route.data.subscribe(data => {
      this.blocks = data.blocks;
      this.setTitle(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.title)
      this.setMetaDescription(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.meta)
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  scroll(): void {
    window.setTimeout(
      () => {
        window.scrollTo(0, 400);
      },
      100
    );
  }
}
