import { ContactService } from './../api/contact.service';
import { GtagService } from './../analytics/gtag.service';
import { FbqService } from './../analytics/fbq.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Contact, FormError } from '../models/index';
import { ReCaptchaComponent } from './re-captcha.component';

@Component({
  selector: 'app-contact-form-base',
  templateUrl: './contact-form-base.component.html',
  styleUrls: ['./contact-form-base.component.scss']
})

export class ContactFormBaseComponent implements OnInit {
  @ViewChild('recaptcha', { static: false }) recaptcha: ReCaptchaComponent;
  @Input() title: {};
  submitInProgress: boolean;
  success: boolean;
  contact: Contact = new Contact();
  formError: FormError = new FormError();

  constructor(
    public contactService: ContactService,
    public gtagService: GtagService,
    public fbqService: FbqService
  ) { }

  ngOnInit(): void {
    this.success = false;
    this.submitInProgress = false;
  }

  onSubmit(): void {
    if (this.submitInProgress || this.success)
      return;

    this.submitInProgress = true;
    this.formError = new FormError();
    this.recaptcha.execute();
  }

  send(): void {
    this.contactService.send(this.contact)
      .then(response => {
        this.contact = new Contact();
        this.submitInProgress = false;
        this.success = true;
        this.formError = new FormError();

        this.gtagService.sendEvent('contact', 'message_sent', 'contact_form_sent');
        this.fbqService.sendEvent('Lead', { content_name: 'contact_form_sent', content_category: 'message_sent' });
      })
      .catch(response => {
        this.formError = response;
        this.submitInProgress = false;
        this.success = false;
      });
  }

  captchaResponse(response: string): void {
    this.contact['g-recaptcha-response'] = response;
    this.send();
  }
}
