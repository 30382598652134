/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./showcase.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "@angular/flex-layout/extended";
import * as i7 from "@angular/platform-browser";
import * as i8 from "./showcase.component";
var styles_ShowcaseComponent = [i0.styles];
var RenderType_ShowcaseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShowcaseComponent, data: {} });
export { RenderType_ShowcaseComponent as RenderType_ShowcaseComponent };
function View_ShowcaseComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "btn-box--white"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.basePath + _co.block.properties.link); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.block.properties.button; _ck(_v, 2, 0, currVal_3); }); }
function View_ShowcaseComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "showcase-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShowcaseComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.block.properties.link; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.block.properties.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.block.properties.description; _ck(_v, 4, 0, currVal_1); }); }
function View_ShowcaseComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "showcase-video"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["video", 1]], null, 0, "video", [["loop", "true"], ["oncanplay", "this.play()"], ["onloadedmetadata", "this.muted = true"], ["playsinline", "true"]], [[8, "autoplay", 0], [8, "muted", 0], [8, "src", 4], [8, "preload", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = true; var currVal_2 = _co.videoUri; var currVal_3 = "none"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_ShowcaseComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { video: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "showcase"], ["fxLayout", "row"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgStyleImpl, i3.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgStyle, [i3.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "background-image": 0 }), i1.ɵdid(5, 671744, null, 0, i4.DefaultLayoutDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutStyleBuilder], i5.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(6, 933888, null, 0, i6.DefaultStyleDirective, [i1.ElementRef, i5.StyleUtils, i5.MediaMarshaller, i3.ɵNgStyleImpl, i7.DomSanitizer, [6, i3.NgStyle], [2, i5.SERVER_TOKEN], i1.PLATFORM_ID], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(7, { "background-image": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShowcaseComponent_1)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShowcaseComponent_3)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, (("url(" + ((_co.blockImage == null) ? null : ((_co.blockImage.image == null) ? null : _co.blockImage.image.url))) + ")")); _ck(_v, 3, 0, currVal_0); var currVal_1 = "row"; _ck(_v, 5, 0, currVal_1); var currVal_2 = _ck(_v, 7, 0, (("url(" + ((_co.blockImage == null) ? null : ((_co.blockImage.image == null) ? null : _co.blockImage.image.url))) + ")")); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.block; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.videoUri; _ck(_v, 11, 0, currVal_4); }, null); }
export function View_ShowcaseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-showcase", [], null, null, null, View_ShowcaseComponent_0, RenderType_ShowcaseComponent)), i1.ɵdid(1, 4374528, null, 0, i8.ShowcaseComponent, [], null, null)], null, null); }
var ShowcaseComponentNgFactory = i1.ɵccf("app-showcase", i8.ShowcaseComponent, View_ShowcaseComponent_Host_0, { block: "block", videoUri: "videoUri", blockImage: "blockImage" }, {}, []);
export { ShowcaseComponentNgFactory as ShowcaseComponentNgFactory };
