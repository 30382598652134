import { Injectable } from '@angular/core';
import { ApiCallService } from './api-call.service';
import { Contact } from './../models/index';
import { SeoAwareBaseComponent } from './../seo/seo-aware-base.component';

@Injectable()
export class ContactService {
  constructor(private apiCallService: ApiCallService) { }

  send(contact: Contact): Promise<any> {
    contact.referrer_url = SeoAwareBaseComponent.referrer_url;
    return this.apiCallService.post('/contact', contact);
  }
}
