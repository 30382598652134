<div
  *ngIf="sliders.length"
  class="app-slider"
  (mouseenter)="ngOnDestroy()"
  (mouseleave)="initSlider()"
>
  <mat-tab-group
    [selectedIndex]="selectedIndex"
    dynamicHeight
  >
    <mat-tab *ngFor="let slider of sliders">
      <img
        [src]="slider.image.url"
        alt="image"
        class="slide-image"
      >
    </mat-tab>
  </mat-tab-group>
  <button
    type="button"
    class="slider-arrow left"
    (click)="prev()"
  >
    <mat-icon>chevron_left</mat-icon>
  </button>
  <button
    type="button"
    class="slider-arrow right"
    (click)="next()"
  >
    <mat-icon>chevron_right</mat-icon>
  </button>
  <div class="slider-bullets">
    <button
      type="button"
      *ngFor="let slider of sliders; let i = index;"
      [ngClass]="{'active': selectedIndex === i}"
      (click)="selectedIndex = i"
    ></button>
  </div>
</div>
