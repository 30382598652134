// TODO: should be upted when https://github.com/angular/material2/issues/348 is closed
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-error',
  templateUrl: 'form-error.component.html',
  styleUrls: ['form-error.component.scss']
})
export class FormErrorComponent {
  @Input() errors: string[];
  @Input() error: string;
}
